import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import classes from './Widget.scss';

export type ControllerProps = {
  // Here, props passed via controller's `setProps` should be defined.
};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  return (
    <div data-hook="WidgetCssVars-wrapper">
      <button className={classes.newButton}>Site mappings</button>
      <div className={classes.withStyles}>CSS Vars Local styles</div>
      <div className={classes.withOldStyles}>TPA-Style Local styles</div>
    </div>
  );
};

export default Widget;
